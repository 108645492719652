<template>
  <div style="width:100%;height:100%;overflow-y:auto">
    <div class="blog_default">
      <div v-if="view" style="position:relative">
        <section>
          <div style="margin: 15px 0;display:none;">
            <h3 style="margin-bottom:10px;font-size:1.8em">{{view.title}}</h3>
            <div v-if="view.description">
              <div class="articleBox">
                <article class="introText" v-html="view.description"></article>
              </div>
            </div>
          </div>
          <div v-for="item in view.items" style="margin-bottom:15px">
            <figure v-if="item.type=='Pano'">
              <AutoPano :pano="item.linkItem">
                <div @click="clickPano(item)" style="position:relative">
                  <img :src="(((item.linkItem||{}).firstImage||{}).url||'').replace('/0/0/0/0/', '/800/800/1/0/')">
                  <div style="position:absolute;left:0;top:0;bottom:0;right:0;display:flex;justify-content:center;align-items:center">
                    <div class="icon-panorama drop-shadow" style="font-size:50px" />
                  </div>
                </div>
                <template #pano>
                  <div style="position:absolute;bottom:0;top:0;left:0;width:25%">
                  </div>
                  <div style="position:absolute;bottom:0;top:0;right:0;width:25%">
                  </div>
                  <div style="position:absolute;bottom:10px;right:15px;" @click="clickPano(item)">
                    <div class="text-center">
                      <i class="icon-360" style="width:4.5rem;height:4.5rem" />
                    </div>
                    <div class="text-center text-shadow">
                      打开全景
                    </div>
                  </div>
                </template>
              </AutoPano>
              <figcaption v-if="item.title">
                {{ item.title }}
              </figcaption>
              <article v-if="item.description" v-html="item.description">
              </article>
            </figure>
            <figure v-if="item.type=='Image'">
              <div>
                <img :src="((item.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')" @click="showImg(item)">
              </div>
              <figcaption v-if="item.title">
                {{ item.title }}
              </figcaption>
              <article v-if="item.description" v-html="item.description">
              </article>
            </figure>
            <template v-if="item.type=='Text'">
              <h3 v-if="item.title">
                {{ item.title }}
              </h3>
              <article v-if="item.description" v-html="item.description">
              </article>
            </template>
            <figure v-if="item.type=='Audio'">
              <div class="content">
                <audio :title="item.title" :src="(item.file||{}).url" controls @playing="closeOtherAudios($event)" />
              </div>
              <figcaption v-if="item.title">
                {{ item.title }}
              </figcaption>
              <article v-if="item.description" v-html="item.description">
              </article>
            </figure>
            <figure v-if="item.type=='Video'">
              <video style="width:100%"
                     :title="item.title"
                     :src="(item.file||{}).url"
                     :poster="((item.file||{}).url||'').replace('/0/0/0/0/', '/800/0/0/0/')"
                     controls controlslist="nodownload"
                     webkit-playsinline="true"
                     playsinline="true"
                     @playing="closeOtherAudios($event)" />
              <figcaption v-if="item.title">
                {{ item.title }}
              </figcaption>
              <article v-if="item.description" v-html="item.description">
              </article>
            </figure>
            <template v-if="item.type=='Link'">
              <div class="content">
                <a :title="item.title" :href="item.url" class="link"><i class="fas fa-link" />{{ item.title }}</a>
              </div>
              <article v-if="item.description" v-html="item.description">
              </article>
            </template>
            <template v-if="item.type=='Location'">
              <BlogItemMap :item="item"></BlogItemMap>
            </template>
            <template v-if="item.type=='Coordinate'&&item.file">
              <MapView :geojsonUrl="item.file.url" :dragable="true" height="400px"></MapView>
            </template>
          </div>
          <div style="height:85px"></div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
  import 'viewerjs/dist/viewer.css'
  import Viewer from 'viewerjs'
  import AutoPano from '../../BlogSkins/AutoPano'
  import BlogItemMap from '../../BlogSkins/BlogItemMap'
  import MapView from '../../Map/MapView'
  export default {
    components: {
      AutoPano,
      BlogItemMap,
      MapView
    },
    props: {
      blog: {}
    },
    data() {
      return {
        bgaudio: false
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getComonent: {
        default: {}
      },
      getFunc: {
      }
    },
    watch: {
    },
    computed: {
      view() {
        if (this.blog) {
          return this.blog
        }
        return {
          user: this.publicData.tour.user || {},
          items: this.publicData.groupItems || [],
          ...this.getGroup(this.publicData.groupGuid)
        }
      },
      groups() {
        if (this.publicData.tour) {
          return this.publicData.tour.groups
        } else {
          return []
        }
      },
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        events: {
        },
        funcs: {
        }
      })
    },
    destroyed() {
    },
    methods: {
      getGroup(groupGuid) {
        console.log(groupGuid, this.groups)
        for (var i in this.groups) {
          if (this.groups[i].guid == groupGuid) {
            return this.groups[i]
          }
        }
      },
      showImg(item) {
        var maxOffsetPercentage = 0.9;
        var image = new Image()
        image.src = item.file.url
        image.alt = item.title
        var viewer = new Viewer(image, {
          title: (image, imageData) => {
            return `${image.alt} (${imageData.naturalWidth} × ${imageData.naturalHeight})`
          },
          hidden: () => {
            viewer.destroy();
          },
          toolbar: {
            zoomIn: 1,
            zoomOut: 1,
            oneToOne: 1,
            reset: 1,
            prev: false,
            play: false,
            next: false,
            rotateLeft: 1,
            rotateRight: 1,
            flipHorizontal: 1,
            flipVertical: 1
          },
          navbar: false,
          move: (event) => {
            console.log(viewer)
            var viewerData = viewer.viewerData;
            var imageData = viewer.imageData;
            var maxOffsetHorizontal = viewerData.width * maxOffsetPercentage;
            var maxOffsetVertical = viewerData.height * maxOffsetPercentage;
            var detail = event.detail;
            var left = detail.x;
            var top = detail.y;
            var right = viewerData.width - (left + imageData.width);
            var bottom = viewerData.height - (top + imageData.height);

            if (
              // Move left
              (detail.x < detail.oldX && right > 0 && right > maxOffsetHorizontal)

              // Move right
              || (detail.x > detail.oldX && left > 0 && left > maxOffsetHorizontal)

              // Move up
              || (detail.y < detail.oldY && bottom > 0 && bottom > maxOffsetVertical)

              // Move down
              || (detail.y > detail.oldY && top > 0 && top > maxOffsetVertical)
            ) {
              event.preventDefault();
            }
          },
        });
        viewer.show();
      },
      toPano() {
        this.$emit('toPano')
      },
      toUserUrl() {
        this.$emit('toUserUrl')
      },
      praise() {
        this.$emit('praise')
      },
      share() {
        this.$emit('share')
      },
      clickPano(item) {
        if (this.toPano) {
          this.toPano(item.linkItem.guid)
        }
      },
      closeOtherAudios(e) {
        const thisav = e.srcElement
        const av = [...document.getElementsByTagName('audio'), ...document.getElementsByTagName('video')]
        console.log(av)
        for (const i in av) {
          if (thisav != av[i]) { av[i].pause() }
        }
      },
      getBgImgStyle(url, obj) {
        return {
          ...obj,
          'background-image': `url(${url})`,
          'background-size': 'cover',
          'background-position': 'center',
        }
      },

    }
  }
</script>
<style lang="css" scoped>
  img {
    width: 100%
  }

  figure {
    margin: 0;
    width: 100%;
  }

  h1 {
    padding: 30px 25px 0;
    font-size: 1.8em;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 0;
  }

  a {
    color: #172026
  }

  h3 {
    font-size: 1.6em;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 25px;
  }

  article {
    font-size: 1.6em;
    line-height: 1.4;
    padding: 0 25px;
    text-indent: 0;
    letter-spacing: 0.05em;
    text-align: justify;
    color: #666;
  }

  figcaption {
    font-size: 1.6em;
    line-height: 1.4;
    font-weight: 700;
    margin: 0 25px;
    word-break: break-all;
    margin-bottom: 0.3em;
    color: #666;
  }

  .blog_default {
    max-width: 640px;
    margin: auto;
    background-color: #fff;
    font-size: 10px;
    color: #172026;
    overflow: hidden;
  }

  .content {
    margin: 0 25px;
  }

  .link {
    font-size: 14px;
    color: #007bff;
    /*text-decoration: underline;*/
  }

  video::-internal-media-controls-download-button {
    display: none;
  }

  video::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }

  audio::-internal-media-controls-download-button {
    display: none;
  }

  audio::-webkit-media-controls-enclosure {
    overflow: hidden;
  }

  audio::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }

  .icon-praise {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyMiIgdmlld0JveD0iMCAwIDI0IDIyIj4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBmaWxsOiAjMTcyMDI2OwogICAgICB9CiAgICA8L3N0eWxlPgogIDwvZGVmcz4KICA8cGF0aCBpZD0ibGpfNzAiIGRhdGEtbmFtZT0ibGogNzAiIGNsYXNzPSJjbHMtMSIgZD0iTTEyLDIxLjk1MWExLjYzNSwxLjYzNSwwLDAsMS0uNzU1LS4xODZsLS4wNjctLjAzNEEyNy43MjgsMjcuNzI4LDAsMCwxLDUuMjUsMTcuNzU2YTE3LjgwNywxNy44MDcsMCwwLDEtMy42LTQuMjg3QTExLjU1NSwxMS41NTUsMCwwLDEsMCw3LjYwOSw3LjY4NSw3LjY4NSwwLDAsMSw0LjIxMi42NzMsNy4wNTMsNy4wNTMsMCwwLDEsMTIsMS43MzEsNy4wNTMsNy4wNTMsMCwwLDEsMTkuNzg2LjY3Miw3LjY4NSw3LjY4NSwwLDAsMSwyNCw3LjZhMTEuNTU1LDExLjU1NSwwLDAsMS0xLjY0OSw1Ljg2NSwxNy44MSwxNy44MSwwLDAsMS0zLjYsNC4yODcsMjcuNzIzLDI3LjcyMywwLDAsMS01LjkyNywzLjk3NGwtLjA2Ny4wMzRBMS42MzUsMS42MzUsMCwwLDEsMTIsMjEuOTUxWk03LjMxMywyLjAxOEE1LjQ3OCw1LjQ3OCwwLDAsMCwxLjk2OSw3LjYwOWMwLDYuNzE1LDcuNjQ1LDExLjA1OCwxMC4wMzEsMTIuMjQ3LDIuMzg3LTEuMTksMTAuMDMxLTUuNTMyLDEwLjAzMS0xMi4yNDdBNS41OTEsNS41OTEsMCwwLDAsMTguNjEyLDIuNGE1LjE4MSw1LjE4MSwwLDAsMC01Ljg3OCwxLjQ1NkwxMiw0LjdsLS43MzEtLjg0NkE1LjI0Miw1LjI0MiwwLDAsMCw3LjMxMywyLjAxOFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgMC4wNDkpIi8+Cjwvc3ZnPgo=') 50% 50% no-repeat;
    background-size: 100%;
  }
</style>
